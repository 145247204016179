import React, { useState, useEffect } from "react";
import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [app, setApp] = useState("");
  const [continent, setContinent] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [startDate, setStartDate] = useState(() => (new Date(Date.now() + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));
  const [endDate, setEndDate] = useState(() => (new Date(Date.now() + ((5 * 60 + 30) * 60 * 1000))).toISOString().substring(0, 10));

  useEffect(() => {

    refresh();
  }, []);

  const refresh = () => {

    const apiUrl = "https://analytics.operate.live";
    var apiParam = {
      method: 'POST',
      headers: { startDate: (new Date((new Date(startDate)) - ((5 * 60 + 30) * 60 * 1000))).toISOString(), endDate: (new Date((new Date(endDate)) - ((-18 * 60 - 30) * 60 * 1000))).toISOString() }
    };
    fetch(apiUrl, apiParam)
      .then(res => res.json() || res.text())
      .then(body => {
        setData(body.data);
      })
      .catch(err => alert(JSON.stringify(err)));

  };

  return (
    <div className="App">
      <table>
        <tr><td><input type="text" placeholder="App" value={app} onChange={(e) => setApp(e.target.value)} /></td>
          <td><input type="text" placeholder="From Date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /></td>
          <td><input type="text" placeholder="End Date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /></td>
          <td><input type="text" placeholder="Continent" value={continent} onChange={(e) => setContinent(e.target.value)} /></td>
          <td><input type="text" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} /></td>
          <td><input type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} /></td>
          <td><input type="button" value="Refresh" onClick={(e) => refresh()} /></td>
        </tr>
      </table>

      <table>
        <tr><th>App</th><th>Date</th><th>Continent</th><th>Country</th><th>City</th><th>Location</th><th>URL</th><th>Browser</th></tr>
        {data
          .filter(item => item.app.toLowerCase().includes(app.toLowerCase()))
          .filter(item => (JSON.parse((item.geo)).continent || { names: { en: "" } }).names.en.toLowerCase().includes(continent.toLowerCase()))
          .filter(item => (JSON.parse((item.geo)).country || { names: { en: "" } }).names.en.toLowerCase().includes(country.toLowerCase()))
          .filter(item => (JSON.parse((item.geo)).city || { names: { en: "" } }).names.en.toLowerCase().includes(city.toLowerCase()))
          .map((row, key) => {
            return (<tr><td><abbr title={row.data}>{row.app}</abbr></td><td>{(new Date(row.date)).toDateString().substring(0, 15) + " " + (new Date(row.date)).toTimeString().substring(0, 8)}</td>
              <td><abbr title={row.geo}>{JSON.parse(row.geo).continent.names.en}</abbr></td>
              <td>{(JSON.parse((row.geo)).country || { names: { en: "" } }).names.en}</td>
              <td><a href={'https://www.google.com/search?q=' +
                (JSON.parse((row.geo)).city || { names: { en: "" } }).names.en} target='_blank'>
                {(JSON.parse((row.geo)).city || { names: { en: "" } }).names.en}
              </a>
              </td>
              <td><a href={'https://www.google.com/search?q=' +
                "latitude:" + JSON.stringify(JSON.parse(row.geo).location.latitude) + ',' + "longitude:" + JSON.stringify(JSON.parse(row.geo).location.longitude)}
                target='_blank'>{"latitude:" + JSON.stringify(JSON.parse(row.geo).location.latitude) + ',' + "longitude:" + JSON.stringify(JSON.parse(row.geo).location.longitude)}</a>
              </td>
              <td>{JSON.parse(row.data).referer}</td>
              <td><abbr title={JSON.parse(row.data)["user-agent"]}>
                {(JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[0] ? (JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[0] + ";" : ""}
              </abbr>
                {(JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[1] ? (JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[1] + ";" : ""}
                {(JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';').length > 3 ? ((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[1] ? (JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[2] + ";" : "") : ""}
                {(JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';').length > 4 ? ((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[1] ? (JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[3] + ";" : "") : ""}
                <a href={'https://www.google.com/search?q=' + ((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';').length - 1)].split("Build")[0] || "")}
                  target='_blank'>{((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';')[((JSON.parse(row.data)["user-agent"] || "").substring(0, (JSON.parse(row.data)["user-agent"] || "").indexOf(')')).substring((JSON.parse(row.data)["user-agent"] || "").indexOf('(') + 1).split(';').length - 1)] || "")}</a>
              </td>
            </tr>)
          })}
      </table>
    </div >
  );
}

export default App;
